import Axios from 'axios';
import { apiUrl, apiVersion } from '../utils/Constants';
import { Pump } from '../models/Pump';

export const getPumpListEndpoint = `${apiUrl}/api/v${apiVersion}/outpatient/products/category`;

export const getPumpListByType = async (
  categoryId: number,
  insuranceType: string
) => {
  const { data } = await Axios.get<Pump[]>(
    `${getPumpListEndpoint}/${categoryId}/insuranceType/${insuranceType}`
  );
  return data;
};
