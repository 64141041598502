import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { AppLayout } from './common/components/AppLayout/AppLayout';
import {
  defaultI18nLanguage,
  supportedI18nLanguage,
  SupportedLanguage,
} from './common/utils/Constants';
import { languageNameToI18nCode } from './common/utils/Utils';
import './index.css';
import { CreateOrder } from './pages/CreateOrder/CreateOrder';
import { Otp } from './pages/CreateOrder/OTP/Otp';
import { PhoneNumber } from './pages/CreateOrder/PhoneNumber/PhoneNumber';
import { Language } from './pages/CreateOrder/Steps/Language/Language';
import { Dashboard } from './pages/Dashboard/Dashboard';
import {
  apiUrl,
  apiVersion,
  appName,
  sentryDsn,
  sentryEnvironment,
  sentrySampleRate,
} from './utils/Constants';

const i18nCacheExpirationTime = 8 * 60 * 60 * 1000;
const i18nEndpoint = `${apiUrl}/api/v${apiVersion}/i18n`;

const setupI18n = () =>
  i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      load: 'currentOnly',
      fallbackLng: defaultI18nLanguage,
      lng: languageNameToI18nCode(
        (secureLocalStorage.getItem('language') as SupportedLanguage) ??
          defaultI18nLanguage
      ),
      supportedLngs: supportedI18nLanguage,
      interpolation: {
        escapeValue: false,
      },
      backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            prefix: 'i18n-',
            expirationTime: i18nCacheExpirationTime,
            store: typeof window !== 'undefined' ? window.localStorage : null,
          },
          {
            loadPath: `${i18nEndpoint}?language={{lng}}`,
          },
        ],
      },
    });

const renderApp = () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route index element={<Dashboard />} />
            <Route path='orders'>
              <Route
                path='new'
                element={
                  <AppLayout appName={appName}>
                    <CreateOrder />
                  </AppLayout>
                }
              />
              <Route path='language' element={<Language />} />
              <Route path='otp' element={<Otp />} />
              <Route path='phone' element={<PhoneNumber />} />
            </Route>
          </Route>
          <Route path='*' element={<Language />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

setupI18n()
  .then(() => {
    if (sentryDsn && sentrySampleRate && sentryEnvironment) {
      Sentry.init({
        dsn: sentryDsn,
        integrations: [new BrowserTracing()],
        tracesSampleRate: Number(sentrySampleRate),
        environment: sentryEnvironment,
      });
    }

    renderApp();
  })
  .catch(() => {
    // TODO: handle error
  });
