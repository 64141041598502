import { FunctionComponent, useRef, useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CreateOrderStepProps } from '../../CreateOrderStepProps';
import { CreateOrderRequest } from '../../../../models/CreateOrderRequest';

export const Signature: FunctionComponent<CreateOrderStepProps> = ({
  onOrderRequestChange,
  formikObj,
}) => {
  const padRef = useRef<SignatureCanvas>(null);
  const { t } = useTranslation();
  const [signatureSize, setSignatureSize] = useState<number>(0);
  let trimmedUrl: any = padRef?.current
    ?.getTrimmedCanvas()
    .toDataURL('image/png');

  const loadImage = (url: any) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.crossOrigin = 'anonymous';
      img.src = url;
    });

  const handleSignatureSubmit = async () => {
    const url = padRef?.current?.getCanvas().toDataURL('image/png');
    trimmedUrl = padRef?.current?.getTrimmedCanvas().toDataURL('image/png');
    const urlData = padRef.current?.getCanvas().toDataURL('image/png');
    const img: any = await loadImage(urlData);
    const ctx: HTMLCanvasElement | any = document
      .createElement('canvas')
      .getContext('2d');
    ctx.canvas.width = img.width;
    ctx.canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);
    const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
    let pixels = 0;
    for (let y = 0, i = 0; y < img.height; y += 1)
      for (let x = 0; x < img.width; x += 1, i += 4) {
        if (imageData[i + 3]) {
          pixels += 1;
        }
      }
    setSignatureSize(pixels);
    if (url && pixels > 700) {
      formikObj.handleChange({
        target: { value: url.split(',')[1], name: 'signatureEncodedImg' },
      });
      onOrderRequestChange('signatureEncodedImg', url);
    }
  };
  const getFieldHelperText = (
    field: keyof CreateOrderRequest
  ): string | undefined =>
    formikObj.touched[field] ? formikObj.errors[field] : undefined;

  const handleClearSignaturePad = () => {
    padRef.current?.clear();
    const url = padRef.current?.getTrimmedCanvas().toDataURL('image/png');
    if (url) {
      formikObj.handleChange({
        target: { value: '', name: 'signatureEncodedImg' },
      });
      onOrderRequestChange('signatureEncodedImg', '');
    }
    setSignatureSize(0);
  };
  useEffect(() => {
    if (formikObj.values.signatureEncodedImg) {
      padRef.current?.fromDataURL(
        `data:image/png;base64,${formikObj.values.signatureEncodedImg}`
      );
    }
  }, []);
  const signatureSx = {
    cardMain: {
      boxShadow: 'none',
      overflow: 'auto',
      width: '100%',
      background: 'transparent',
      mt: 1.87,
      '.Mui-error': {
        color: '#d32f2f',
        fontSize: '0.75rem',
      },
    },
    card: {
      border: '0',
      p: 1.25,
      background: 'transparent',
    },
    cardContent: {
      pb: 3,
      border: '0px',
      boxShadow: '0 0 10px 0px #ddd',
      borderRadius: '15px',
      background: '#fff',
      '.sigPad': {
        width: '100%',
      },
    },
    sigPad: {
      width: '100%',
      height: '100%',
    },
    termContent: {
      mt: 0,
      mb: 2,
      fontSize: '0.8rem',
    },
  };
  return (
    <Card sx={signatureSx.cardMain}>
      <Card variant='outlined' sx={signatureSx.card}>
        <CardContent sx={signatureSx.cardContent}>
          <Grid item xs={12}>
            <Typography variant='h5' mb={2}>
              {t('InPatient-ConfirmSignature-SignatureTermsTitle')}
            </Typography>
          </Grid>
          <Grid
            sx={{
              height: { xs: '310px', sm: '240px' },
              overflow: 'auto',
              textAlign: 'justify',
            }}
          >
            <Typography variant='h6' sx={{ mt: 0, mb: 2, fontSize: '1rem' }}>
              {t('InPatient-ConfirmSignature-SignatureTermsTitle')}
            </Typography>

            {t('NewOP-SignaturePage-TermsLegal')
              .split('\n')
              .map((str, index) => {
                const key = `terms${index}`;
                return (
                  <Typography
                    variant='body1'
                    sx={signatureSx.termContent}
                    key={key}
                  >
                    {str}
                  </Typography>
                );
              })}
          </Grid>
        </CardContent>
      </Card>
      <Card variant='outlined' sx={signatureSx.card}>
        <CardContent sx={signatureSx.cardContent}>
          <Typography variant='h6' sx={{ mt: 0, mb: 2, fontSize: '19px' }}>
            {`${t('NewLCOP-ConfirmSignature-Congratulations')} ${t(
              'NewLCOP-ConfirmSignature-AcceptPump'
            )}`}
          </Typography>

          <Grid
            container
            sx={{
              width: '100%',
              backgroundColor: '#eceef5',
              borderRadius: '10px',
              mt: '20px',
              m: '0 auto',
              ml: 0,
            }}
          >
            <Grid item xs={9} sm={11} sx={{ width: '100% !important', pl: 0 }}>
              <SignatureCanvas
                ref={padRef}
                canvasProps={{
                  style: {
                    height: 300,
                    width: '100%',
                    marginTop: '10px',
                    marginLeft: '10px',
                  },
                }}
                onEnd={handleSignatureSubmit}
                clearOnResize={false}
              />
            </Grid>
            {(formikObj.values.signatureEncodedImg ||
              trimmedUrl?.split(',')[1].length > 108) && (
              <Grid
                item
                xs={3}
                sm={1}
                sx={{ textAlign: { xs: 'center', sm: 'right' } }}
              >
                <Button
                  sx={{
                    color: 'white',
                    backgroundColor: '#4a6b89',
                    borderRadius: '3px',
                    padding: '5px',
                    marginBottom: '5px',
                    minWidth: '10px',
                    fontSize: '1rem',
                    mt: { xs: '5px', sm: '10px' },
                    mr: { xs: '5px', sm: '10px' },
                    '&:hover': {
                      color: 'white',
                      backgroundColor: '#4a6b89',
                    },
                  }}
                  onClick={handleClearSignaturePad}
                >
                  <DeleteOutlineIcon />
                </Button>
              </Grid>
            )}
          </Grid>
          {formikObj.touched.signatureEncodedImg &&
            formikObj.errors.signatureEncodedImg && (
              <span
                style={{
                  color: '#d32f2f',
                  fontFamily: 'Roboto ,Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  fontSize: '0.75rem',
                  lineHeight: 1.66,
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '14px',
                  marginBottom: 0,
                  marginLeft: '14px',
                }}
              >
                {signatureSize < 700 && signatureSize >= 1
                  ? t('NewLC-Signature-PleaseEnter')
                  : getFieldHelperText('signatureEncodedImg')}
              </span>
            )}
        </CardContent>
      </Card>
    </Card>
  );
};
