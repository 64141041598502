import { FunctionComponent, useEffect } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CreateOrderStepProps } from '../../CreateOrderStepProps';

interface FileProps {
  type: string;
  fileName: string;
  encodedImg: string;
  file: File;
  isLoading: boolean;
  index: number;
  isDelete: boolean;
}
export const Review: FunctionComponent<CreateOrderStepProps | any> = ({
  createOrderRequest,
  setActiveStep,
  setPreviousStep,
}) => {
  const { t } = useTranslation();
  const previewImageStyle = {
    width: '100%',
    border: '2px solid #4A6B89',
    borderRadius: '5px',
    height: '100%',
  };
  const attachementPreview = {
    position: 'relative',
    width: '120px',
    height: '120px',
    margin: 0.6,
    '.MuiSvgIcon-root': {
      position: 'absolute',
      right: '3px',
      top: '3px',
      color: 'white',
      background: '#0000007a',
      borderRadius: '3px',
    },
    '.MuiTypography-root': {
      background: '#0000007a',
      color: '#fff',
      position: 'absolute',
      bottom: '7px',
      width: '100%',
      padding: '0px 10px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  };
  const handleDataChange = (index: number) => {
    setPreviousStep(index);
    setActiveStep(index);
  };
  const labelStyle = {
    color: '#6a859e',
    mt: 2,
    fontSize: '15px',
  };
  const fontStyle = {
    fontWeight: 'bold',
    fontSize: '15px',
  };
  const cardStyle = {
    m: 2,
    borderRadius: 3,
    p: 1.25,
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: '#e7eef7',
        mt: 4,
        width: '-webkit-fill-available',
      }}
    >
      <Grid sx={{ m: 1.25, pl: 0.6 }}>
        <Typography variant='h6' sx={{ fontWeight: 'bold', pb: 0.6 }}>
          {t('OutPatient-OrderReview-Title')}
        </Typography>
      </Grid>
      <Typography sx={{ m: 1.25, pl: 0.6 }}>
        {t('NewOP-ReviewPage-Message')}
      </Typography>
      <Card sx={cardStyle}>
        <CardContent>
          <Grid container rowSpacing={1} sx={{ pb: 1.25 }}>
            <Grid item xs={11} sx={{ p: 0.6, pl: 0 }}>
              <Typography
                variant='h6'
                sx={{ fontStyle, fontWeight: 600, fontSize: 16 }}
              >
                {t('NewOP-ReviewPage-PatientDetails')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button
                sx={{
                  color: '#6a859e',
                  backgroundColor: 'white',
                  textAlign: 'right',
                  minWidth: 35,
                }}
                onClick={() => handleDataChange(0)}
              >
                <EditOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={3}>
            <Grid item xs={6} sm={4}>
              <InputLabel id='firstName' sx={labelStyle}>
                {t('OutPatient-OrderReview-FirstNamePrompt')}
              </InputLabel>
              <Typography aria-labelledby='firstName' sx={fontStyle}>
                {createOrderRequest.firstName}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel id='lastName' sx={labelStyle}>
                {t('OutPatient-OrderReview-LastNamePrompt')}
              </InputLabel>
              <Typography aria-labelledby='lastName' sx={fontStyle}>
                {createOrderRequest.lastName}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel id='dateOfBirth' sx={labelStyle}>
                {t('OutPatient-OrderReview-BirthDatePrompt')}
              </InputLabel>
              <Typography aria-labelledby='dateOfBirth' sx={fontStyle}>
                {moment(createOrderRequest.dateOfBirth).format('MM/DD/YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel id='isActualBabyDateOfBirth' sx={labelStyle}>
                {t('NewOP-ReviewPage-Baby')}
              </InputLabel>
              <Typography
                aria-labelledby='isActualBabyDateOfBirth'
                sx={fontStyle}
              >
                {createOrderRequest.isActualBaby === 'true'
                  ? t('NewOP-MomDetails-Actual')
                  : t('NewOP-MomDetails-Expected')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel id='babyDateOfBirth' sx={labelStyle}>
                {createOrderRequest.isActualBaby === 'true'
                  ? t('Orders-Details-BabysDOB')
                  : t('NewOP-ReviewPage-BabyDueDate')}
              </InputLabel>
              <Typography aria-labelledby='babyDateOfBirth' sx={fontStyle}>
                {moment(createOrderRequest.childDateOfBirth).format(
                  'MM/DD/YYYY'
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={3}>
            <Grid item xs={6} sm={4}>
              <InputLabel id='street1' sx={labelStyle}>
                {t('OutPatient-OrderReview-AddressLine1Prompt')}
              </InputLabel>
              <Typography aria-labelledby='street1' sx={fontStyle}>
                {createOrderRequest.street1}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel id='street2' sx={labelStyle}>
                {t('OutPatient-OrderReview-AddressLine2Prompt')}
              </InputLabel>
              <Typography aria-labelledby='street2' sx={fontStyle}>
                {createOrderRequest.street2}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel id='city' sx={labelStyle}>
                {t('OutPatient-OrderReview-CityPrompt')}
              </InputLabel>
              <Typography aria-labelledby='city' sx={fontStyle}>
                {createOrderRequest.city.split('_').join(' ')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel id='state' sx={labelStyle}>
                {t('OutPatient-OrderReview-StatePrompt')}
              </InputLabel>
              <Typography aria-labelledby='state' sx={fontStyle}>
                {createOrderRequest.state.split('_').join(' ')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <InputLabel id='zip' sx={labelStyle}>
                {t('OutPatient-OrderReview-ZipCodePrompt')}
              </InputLabel>
              <Typography aria-labelledby='zip' sx={fontStyle}>
                {createOrderRequest.zip}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={cardStyle}>
        <CardContent>
          <Grid container rowSpacing={1} sx={{ pb: 1.25 }}>
            <Grid item xs={11} sx={{ p: 0.6, pl: 0 }}>
              <Typography
                variant='h6'
                sx={{ fontStyle, fontWeight: 600, fontSize: 16 }}
              >
                {`${t('NewOP-SelectProvider-DoctorInsurance')} ${t(
                  'Deliveries-Details-DetailsTabTitle'
                )}`}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button
                sx={{
                  color: '#6a859e',
                  backgroundColor: 'white',
                  textAlign: 'right',
                  minWidth: 35,
                }}
                onClick={() => handleDataChange(1)}
              >
                <EditOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={3}>
            <Grid item sm={4}>
              <InputLabel id='product' sx={labelStyle}>
                {createOrderRequest.providerId === '' ||
                createOrderRequest.providerId === null
                  ? `${t('NewPrompt')} ${t('NewOP-SelectProvider-Doctor')}`
                  : t('NewOP-SelectProvider-Doctor')}
              </InputLabel>
              <Typography aria-labelledby='product' sx={fontStyle}>
                {createOrderRequest.providerName}
              </Typography>
            </Grid>
            <Grid container item sm={8}>
              <InputLabel id='attachment' sx={labelStyle}>
                {t('NewOP-ReviewPage-Attachments')}
              </InputLabel>
              <Grid container>
                {createOrderRequest.prescriptionAttachments &&
                  createOrderRequest?.prescriptionAttachments
                    ?.filter((file: FileProps) => file?.isDelete !== true)
                    .map((file: FileProps | any) => (
                      <Grid item container key={file} sx={{ width: '120px' }}>
                        <Box sx={attachementPreview} key={file}>
                          <img
                            key={file}
                            aria-labelledby='providerAttachments'
                            src={`data:image/jpeg;base64,${file?.encodedImg}`}
                            style={previewImageStyle}
                          />
                          <Typography
                            variant='subtitle1'
                            sx={{ wordBreak: 'break-all' }}
                          >
                            {file?.fileName.length <= 15
                              ? file?.fileName
                              : file?.fileName.substring(0, 15).concat('...')}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={3}>
            <Grid item sm={4}>
              <InputLabel id='isMedicaid' sx={labelStyle}>
                {t('NewOP-ReviewPage-InsuranceType')}
              </InputLabel>
              <Typography aria-labelledby='isMedicaid' sx={fontStyle}>
                {createOrderRequest.isMedicaid === 'true'
                  ? t('NewOP-Insurance-Medicaid')
                  : t('NewOP-Insurance-Commercial')}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <InputLabel id='insuranceName' sx={labelStyle}>
                {createOrderRequest.insuranceId === ''
                  ? t('InsurancePayers-Index-NewItemPrompt')
                  : t('NewOP-reviewPage-InsuranceName')}
              </InputLabel>
              <Typography aria-labelledby='insuranceName' sx={fontStyle}>
                {createOrderRequest.insuranceName}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <InputLabel id='insuranceName' sx={labelStyle}>
                {t('Orders-Details-PolicyNumber')}
              </InputLabel>
              <Typography aria-labelledby='insuranceName' sx={fontStyle}>
                {createOrderRequest.insurancePolicyNumber}
              </Typography>
            </Grid>
            <Grid item>
              <InputLabel id='insuranceAttachment' sx={labelStyle}>
                {t('NewOP-ReviewPage-Attachments')}
              </InputLabel>
              <Grid container>
                {createOrderRequest.insuranceAttachments &&
                  createOrderRequest?.insuranceAttachments
                    ?.filter((file: FileProps) => file?.isDelete !== true)
                    .map((file: FileProps | any) => (
                      <Grid item container key={file} sx={{ width: '120px' }}>
                        <Box sx={attachementPreview} key={file}>
                          <img
                            key={file}
                            aria-labelledby='providerAttachments'
                            src={`data:image/jpeg;base64,${file?.encodedImg}`}
                            style={previewImageStyle}
                          />
                          <Typography
                            variant='subtitle1'
                            sx={{ wordBreak: 'break-all' }}
                          >
                            {file?.fileName.length <= 15
                              ? file?.fileName
                              : file?.fileName.substring(0, 15).concat('...')}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={cardStyle}>
        <CardContent>
          <Grid container rowSpacing={1}>
            <Grid item xs={11} sx={{ p: 0.6, pl: 0 }}>
              <Typography
                variant='h6'
                sx={{ fontStyle, fontWeight: 600, fontSize: 16 }}
              >
                {t('NewOP-ReviewPage-PumpDetails')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button
                sx={{
                  color: '#6a859e',
                  backgroundColor: 'white',
                  textAlign: 'right',
                  minWidth: 35,
                }}
                onClick={() => handleDataChange(2)}
              >
                <EditOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container rowSpacing={1}>
            <Grid item xs={12} sm={6} sx={{ p: 0 }}>
              <Card sx={{ borderRadius: 3, mt: 1 }}>
                <CardContent>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={3}
                    sx={{ pl: 2 }}
                  >
                    <Grid item sm={6} sx={{ m: 0, display: 'flex' }}>
                      <img
                        src={createOrderRequest.product?.imageUrl}
                        style={{
                          paddingTop: 0.6,
                          minWidth: 80,
                          paddingLeft: 0,
                          padding: 0,
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} sx={{ pl: '5px !important' }}>
                      <InputLabel
                        id='product'
                        sx={{ labelStyle, mt: 0.6, mb: 0.6 }}
                      >
                        {t('NewOP-ReviewPage-PumpName')}
                      </InputLabel>
                      <Typography aria-labelledby='product' sx={fontStyle}>
                        {createOrderRequest.product?.model}
                      </Typography>
                      <Typography
                        aria-labelledby='product'
                        sx={{ ...fontStyle, mt: 0.6 }}
                      >
                        {`${t('OutPatient-SelectProduct-AccessoryFee')}: $${
                          createOrderRequest.product?.salePrice
                        }`}
                      </Typography>
                      <Typography
                        aria-labelledby='product'
                        sx={{ ...fontStyle, mt: 0.6 }}
                      >
                        {t(
                          `ProductCatalogItemDescription${createOrderRequest?.product?.id}`
                        )
                          .split('\n')
                          .map((str, index) => (
                            <p key={`${index.toString()}`}>{str}</p>
                          ))}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={cardStyle}>
        <CardContent>
          <Grid container rowSpacing={1} sx={{ pb: 1.25 }}>
            <Grid item xs={11} sx={{ p: 0.6, pl: 0 }}>
              <Typography
                variant='h6'
                sx={{ fontStyle, fontWeight: 600, fontSize: 16 }}
              >
                {t('NewOP-ReviewPage-Signature')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button
                sx={{
                  color: '#6a859e',
                  backgroundColor: 'white',
                  textAlign: 'right',
                  minWidth: 35,
                }}
                onClick={() => handleDataChange(3)}
              >
                <EditOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid xs={6}>
            <img
              src={`data:image/jpeg;base64,${createOrderRequest.signatureEncodedImg}`}
              style={{
                paddingTop: 0.6,
                width: '90%',
                minWidth: 170,
              }}
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
