import {
  useRef,
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Modal, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import Webcam from 'react-webcam';
import { styled } from '@mui/material/styles';

const modalContentSx = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    maxWidth: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    video: {
      width: '100%',
    },
  },
  button: {
    background: '#4A6B89 ',
    color: '#fff ',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '130px',
    textTransform: 'capitalize',
  },
  bottomButtons: {
    boxShadow: 'none ',
    fontSize: '13px',
    fontWeight: '700 ',
  },
};

const CameraBox = styled('div')(({ theme }) => ({
  video: {
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

interface CameraModalProps {
  onSave: (field: string, data: string) => void;
  open: boolean;
  onClose: () => void;
  filedName: string;
}

interface CameraModeState {
  facingMode:
    | string
    | {
        exact: string;
      };
}

export const CameraModal: FunctionComponent<CameraModalProps> = ({
  onSave,
  open,
  onClose,
  filedName,
}) => {
  const { t } = useTranslation();
  const [captured, setCaptured] = useState<string>('');
  const [cameraErr, setCamerErr] = useState<string>('');
  const [backCameraErr, setBackCameraErr] = useState<string>('');
  const [cameraMode, setCameraMode] = useState<CameraModeState>({
    facingMode: 'user',
  });
  const webcamRef = useRef<Webcam>(null);
  const handleSave = () => {
    onSave(filedName, captured);
    setCaptured('');
    onClose();
  };
  const capture = useCallback(() => {
    const imageSrc: string | any = webcamRef?.current?.getScreenshot();
    setCaptured(imageSrc);
  }, [webcamRef]);
  const handleError = (err: any) => {
    setCamerErr(String(err));
  };
  const handleCancel = () => {
    setCaptured('');
  };
  const handleCameraMode = () => {
    setCameraMode({
      facingMode:
        cameraMode.facingMode === 'user' ? { exact: 'environment' } : 'user',
    });
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: { exact: 'environment' },
        },
      })
      .catch((err) => {
        setBackCameraErr(err?.name);
        /* handle the error */
      });
    setCaptured('');
  }, []);

  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={onClose}
      aria-labelledby='child-modal-title'
      aria-describedby='child-modal-description'
    >
      <CameraBox sx={modalContentSx.box}>
        <Button
          sx={{
            position: 'absolute',
            top: '0px',
            right: '-10px',
            color: '#000',
          }}
          endIcon={<CloseIcon />}
          onClick={onClose}
        />
        {/* Camera Error Section */}
        {cameraErr && (
          <Typography color='red' p={5}>
            {cameraErr}
          </Typography>
        )}
        {/* Camera Section */}
        {!captured ? (
          !cameraErr && (
            <>
              <Webcam
                ref={webcamRef}
                screenshotFormat='image/jpeg'
                onUserMediaError={handleError}
                videoConstraints={cameraMode}
              />
              <Stack
                spacing={2}
                direction='row'
                mt={2}
                sx={{ justifyContent: 'center' }}
              >
                <Button variant='contained' onClick={capture}>
                  <CameraAltIcon />
                </Button>
                {!backCameraErr && (
                  <Button variant='contained' onClick={handleCameraMode}>
                    <CameraswitchIcon />
                  </Button>
                )}
              </Stack>
            </>
          )
        ) : (
          <>
            <img src={captured} alt='preview-img' />
            <Stack
              spacing={2}
              mt={2}
              direction='row'
              sx={{ justifyContent: 'center' }}
            >
              <Button
                variant='contained'
                onClick={handleCancel}
                sx={{
                  background: '#E7EEF7 !important',
                  color: '#4A6B89',
                }}
              >
                {t('CancelPrompt')}
              </Button>
              <Button
                variant='contained'
                sx={{
                  background: '#E6863E !important',
                  color: '#fff',
                }}
                onClick={handleSave}
              >
                {t('NewLC-Camera-Save')}
              </Button>
            </Stack>
          </>
        )}
      </CameraBox>
    </Modal>
  );
};
