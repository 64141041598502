import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import image from '../../../assets/images/baby-mom.jpg';
import logo from '../../../assets/images/logo-bpd.png';
import { I18nLanguage } from '../../../common/utils/Constants';
import { badge, theme } from '../../../common/utils/Theme';
import { i18nLanguageCodeToName } from '../../../common/utils/Utils';
import { SendVerificationCodeRequest } from '../../../models/SendVerificationCodeRequest';
import { ValidateVerificationCodeRequest } from '../../../models/ValidateVerificationCodeRequest';
import {
  sendVerificationCode,
  validateVerificationCode,
} from '../../../services/VerificationService';

const notify = (message: any) => {
  toast.error(message, { position: 'top-center' });
};
export const Otp: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState<string>('');
  const focusInput = useRef<null | HTMLInputElement>(null);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const otpSx = {
    boxStyle: {
      textAlign: 'center',
    },
    innerBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageOutline: {
      color: '#e6863e',
      width: '50px',
      height: '50px',
    },
    heading: {
      color: '#567391',
      fontSize: '20px',
      fontWeight: '600',
      mt: 1,
    },
    verification: {
      fontWeight: '600',
      marginBottom: '5px',
      color: '#00182d',
      fontSize: '20px',
    },
    verificationText: {
      fontSize: '14px',
      color: '#4a6b89',
      pt: 0.8,
      pb: 4,
      display: 'inline-flex',
    },
    otpInput: {
      color: '#899dad',
      fontSize: '13px',
      backgroundColor: '#F5F6FA',
      fontWeight: '400 ',
      height: '40px ',
      m: 0,
      width: '100%',
      maxWidth: '100%',
      '.MuiAutocomplete-input': {
        padding: '1.5px 4px 7.5px 6px !important',
      },
    },
    buttonStyles: {
      backgroundColor: '#e6863e',
      margin: '50px auto 30px',
      display: 'block',
      boxShadow: 'none',
      borderRadius: '5px',
      fontSize: '16px',
      textTransform: 'capitalize',
      padding: '8px 25px',
      fontWeight: '600',
      mt: 4.5,
      mb: 3,
    },
    typographyH6: {
      fontSize: '12px',
      fontWeight: '400',
      color: '#4a6b89',
      textAlign: 'center',
    },
    subGrid: {
      height: '100vh',
      width: '100%',
      [theme.breakpoints.between('xs', 'sm')]: {
        display: 'none',
        maxWidth: '0%',
      },
    },
  };

  useEffect(() => {
    if (!state?.phoneNumber && !secureLocalStorage.getItem('phoneNumber')) {
      navigate('/orders/language');
    }
  }, []);
  useEffect(() => {
    if (focusInput) {
      setTimeout(() => {
        focusInput.current?.focus();
      }, 500);
    }
  }, []);
  const handleBack = () => {
    navigate(`/orders/phone`, { state: { phoneNumber: state?.phoneNumber } });
  };
  const handleGetVerificationCode = async () => {
    try {
      const language = i18nLanguageCodeToName(i18n.language as I18nLanguage);

      const request = new SendVerificationCodeRequest(
        state?.phoneNumber,
        language
      );

      await sendVerificationCode(request);
      setOtp('');
      focusInput.current?.focus();
    } catch (error) {
      // TODO handle error
    }
  };

  const handleSubmit = async () => {
    try {
      if (otp.length < 6) {
        notify(t('NewOP-EnterVerification-CodeLength'));
      } else {
        const request = new ValidateVerificationCodeRequest(
          state?.phoneNumber,
          otp
        );

        await validateVerificationCode(request);
        secureLocalStorage.setItem('isLogin', true);
        navigate('/orders/new', { state: true, replace: true });
      }
    } catch (error) {
      notify(t('VerificationCodeError'));
      // TODO handle error
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  function formatPhoneNumber(phoneNumberString: string) {
    const cleaned = phoneNumberString.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
  const details = navigator.userAgent;
  const regExp = /android|iphone/i;
  const isMobileDevice = regExp.test(details);
  return (
    <Box
      sx={
        process.env.REACT_APP_CUR_ENV === 'DEV' ||
        process.env.REACT_APP_CUR_ENV === 'UAT'
          ? badge
          : null
      }
    >
      <div className='badge' style={{ height: '100vh', background: 'white' }}>
        <Grid sx={{ height: '100%' }}>
          <ToastContainer />
          <Grid item container sx={{ height: '100%' }}>
            <Grid
              item
              xs={6}
              sx={{
                width: '-webkit-fill-available',
                backgroundImage: `url(${image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'top',
                display: { xs: 'none', sm: 'block' },
                '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
                  display: isMobileDevice ? 'none' : 'block',
                },
              }}
            />
            <Grid
              xs={8}
              sm={6}
              md={6}
              sx={{
                background: 'white',
                '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
                  flexBasis: isMobileDevice ? '100%' : '50%',
                  minWidth: isMobileDevice ? '100%' : '50%',
                },
                [theme.breakpoints.between('xs', 'sm')]: {
                  width: '100%',
                  margin: 'auto',
                  maxWidth: '100%',
                },
              }}
            >
              <Box component='div' sx={otpSx.boxStyle}>
                <Grid sx={{ mt: 6 }}>
                  <Grid
                    component='img'
                    src={logo}
                    sx={{ width: '100%', maxWidth: '400px' }}
                  />
                  <Typography
                    variant='h5'
                    sx={{ mt: 2, mb: 2, color: '#4a6b89' }}
                  >
                    {t('OutPatient-Landing-Title')} to Breast Pump Depot
                  </Typography>
                </Grid>
              </Box>
              <Grid
                item
                container
                alignItems='center'
                direction='column'
                sx={{
                  pt: 4,
                  mt: 3,
                }}
              >
                <Grid item xs={4} sm={6} md={6} sx={{ textAlign: 'center' }}>
                  <Typography
                    component='h1'
                    variant='h5'
                    sx={otpSx.verification}
                  >
                    {t('NewOP-EnterVerification-VerificationCode')}
                  </Typography>
                </Grid>
                <Paper elevation={0} sx={{ textAlign: 'center' }}>
                  <Grid container>
                    <Typography variant='h6' sx={otpSx.verificationText}>
                      {t('NewOP-EnterMobileNumber-SendCode')}
                      <Typography
                        sx={{
                          ...otpSx.verificationText,
                          display: 'contents',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        &nbsp;{formatPhoneNumber(state?.phoneNumber)}&nbsp;
                      </Typography>
                    </Typography>
                  </Grid>
                </Paper>
                <Grid xs={8} sm={6} md={6} sx={{ maxWidth: '100%' }}>
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <TextField
                      id='otp'
                      onKeyDown={handleKeyDown}
                      type='number'
                      sx={{
                        '& input[type=number]': {
                          '-moz-appearance': 'textfield',
                        },
                        '& input[type=number]::-webkit-outer-spin-button': {
                          '-webkit-appearance': 'none',
                          margin: 0,
                        },
                        '& input[type=number]::-webkit-inner-spin-button': {
                          '-webkit-appearance': 'none',
                          margin: 0,
                        },
                      }}
                      value={otp}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9]/g, '')
                          .toString()
                          .slice(0, 6);
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setOtp(e.target.value)
                      }
                      InputProps={{
                        inputRef: focusInput,
                        sx: otpSx.otpInput,
                      }}
                    />
                  </Box>

                  <Button
                    type='submit'
                    variant='contained'
                    sx={otpSx.buttonStyles}
                    onClick={handleSubmit}
                  >
                    {t('NewOP-EnterVerification-Continue')}
                  </Button>
                  <Paper elevation={0}>
                    <ThemeProvider theme={theme}>
                      <Typography variant='h6' sx={otpSx.typographyH6}>
                        {t('NewOP-EnterVerification-Message')}
                        <Link
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            cursor: 'pointer',
                            textAlign: 'center',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            alignItems: 'baseline',
                          }}
                          onClick={handleGetVerificationCode}
                          underline='none'
                        >
                          &nbsp;{`${t('NewOP-EnterVerification-Resend')}`}&nbsp;
                        </Link>
                        {t('NewOP-EnterVerification-GoBack')}
                      </Typography>
                    </ThemeProvider>
                  </Paper>
                  <Button
                    onClick={handleBack}
                    type='submit'
                    variant='contained'
                    sx={{
                      backgroundColor: '#f5f6fa',
                      margin: '50px auto 30px',
                      display: 'flex',
                      boxShadow: 'none',
                      borderRadius: '50%',
                      fontSize: '24px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '50px',
                      minWidth: '50px',
                      color: '#000',
                      padding: '0',
                      '&:hover': {
                        background: '#eef2f5',
                      },
                    }}
                  >
                    <ChevronLeftIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
