import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateOrderRequest } from '../../../../models/CreateOrderRequest';
import { stateNames } from '../../../../utils/Constants';
import { CreateOrderStepProps } from '../../CreateOrderStepProps';

export const UserDetails: FunctionComponent<CreateOrderStepProps> = ({
  onHandleChange,
  formikObj,
}) => {
  const { t } = useTranslation();

  const fieldHasError = (field: keyof CreateOrderRequest): boolean =>
    !!formikObj.touched[field] && Boolean(formikObj.errors[field]);
  const isState =
    formikObj?.values?.state?.length === 2 &&
    stateNames.some(
      (state) =>
        state?.shortCode?.toLowerCase() ===
        formikObj?.values?.state?.toLowerCase()
    );
  const getFieldHelperText = (
    field: keyof CreateOrderRequest
  ): string | undefined =>
    formikObj.touched[field] ? formikObj.errors[field] : undefined;

  const userDetailsSx = {
    cardMain: {
      boxShadow: 'none',
      overflow: 'auto',
      width: '100%',
      background: 'transparent',
      mt: 1.87,
    },
    card: {
      border: '0',
      p: 1.25,
      background: 'transparent',
    },
    completeInput: {
      pt: 0.1875,
      pr: 0.5,
      pb: 0.93,
      pl: 0.75,
    },
    cardContent: {
      pb: 3,
      border: '0px',
      boxShadow: '0 0 10px 0px #ddd',
      borderRadius: '15px',
      background: '#fff',
    },
    datePicker: {
      '.MuiInputBase-formControl': {
        color: '#899dad',
        fontSize: '13px',
        backgroundColor: '#F5F6FA',
        height: '40px',
        m: 0,
      },
      fieldset: {
        border: '1px solid #899dad ',
      },
    },
    uiFormControlRoot: {
      width: '100%',
    },
    inputLabel: {
      color: '#4A6B89',
      fontSize: '12px',
      mt: 0,
      mx: 0,
      mb: 0.625,
      width: '100%',
    },
    textField: {
      border: '0',
      m: 0,
      width: '100%',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#899dad',
        },
        '&:hover fieldset': {
          borderColor: '#899dad',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#899dad ',
        },
      },
    },
    input: {
      color: '#899dad',
      fontSize: '13px',
      backgroundColor: '#F5F6FA',
      fontWeight: '400 ',
      height: '40px ',
      m: 0,
      width: '100%',
      maxWidth: '100%',

      '.MuiAutocomplete-input': {
        padding: '1.5px 4px 7.5px 6px !important',
      },
    },
  };

  const inputFiledProps: TextFieldProps = {
    fullWidth: true,
    size: 'small',
    InputProps: {
      sx: userDetailsSx.input,
    },
  };

  const getStateName = (sCode: string) => {
    let isStateFound;
    if (
      stateNames.some(
        (state) => state?.shortCode?.toLowerCase() === sCode?.toLowerCase()
      ) ||
      stateNames.some(
        (state) => state?.title?.toLowerCase() === sCode?.toLowerCase()
      )
    ) {
      isStateFound = stateNames.find(
        (element) =>
          element?.shortCode?.toLowerCase() === sCode?.toLowerCase() ||
          element?.title?.toLowerCase() === sCode?.toLowerCase()
      );
      return isStateFound?.title.toUpperCase();
    }
    return sCode;
  };
  const filterOptions = (options: any, state: any) => {
    let newOptions: Array<[]> = [];
    const stateName = state?.inputValue.toUpperCase();
    if (
      formikObj?.values?.state?.length > 1 &&
      stateNames.some(
        (data) =>
          data?.title?.toLowerCase() === formikObj?.values?.state?.toLowerCase()
      )
    ) {
      newOptions = options.find(
        (element: object | any) =>
          element.fieldType !== '' &&
          element?.key?.toLowerCase()?.includes(stateName.toLowerCase())
      );
    } else {
      options.forEach((element: object | any) => {
        if (
          element?.key
            ?.toLowerCase()
            ?.includes(state?.inputValue?.toLowerCase())
        )
          newOptions.push(element);
      });
    }
    return newOptions;
  };
  const disableToday = (date: Date | any) =>
    moment(date).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY');
  return (
    <Card sx={userDetailsSx.cardMain}>
      <Card variant='outlined' sx={userDetailsSx.card}>
        <CardContent sx={userDetailsSx.cardContent}>
          <Typography variant='h6' sx={{ mt: 0, mb: 2 }}>
            {t('OutPatient-Begin-SectionTitle')}
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel sx={userDetailsSx.inputLabel}>
                {t('OutPatient-Begin-MomsFirstNamePrompt')}
              </InputLabel>
              <TextField
                autoFocus
                {...formikObj.getFieldProps('firstName')}
                {...inputFiledProps}
                placeholder={t('OutPatient-Begin-MomsFirstNamePrompt')}
                error={fieldHasError('firstName')}
                helperText={getFieldHelperText('firstName')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel sx={userDetailsSx.inputLabel}>
                {t('OutPatient-Begin-MomsLastNamePrompt')}
              </InputLabel>
              <TextField
                {...formikObj.getFieldProps('lastName')}
                {...inputFiledProps}
                placeholder={t('OutPatient-Begin-MomsLastNamePrompt')}
                error={fieldHasError('lastName')}
                helperText={getFieldHelperText('lastName')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel sx={userDetailsSx.inputLabel}>
                {t('OutPatient-Begin-DateOfBirthPrompt')}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  mask='__/__/____'
                  disableFuture
                  components={{
                    OpenPickerIcon: CalendarMonthOutlinedIcon,
                  }}
                  inputFormat='MM/dd/yyyy'
                  value={formikObj.values.dateOfBirth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | null) =>
                    formikObj.handleChange({
                      target: {
                        value: e,
                        name: 'dateOfBirth',
                      },
                    })
                  }
                  shouldDisableDate={disableToday}
                  disableHighlightToday
                  renderInput={(params) => (
                    <TextField
                      {...formikObj.getFieldProps('dateOfBirth')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        formikObj.handleChange({
                          target: {
                            value: e,
                            name: 'dateOfBirth',
                          },
                        })
                      }
                      fullWidth
                      {...params}
                      sx={userDetailsSx.datePicker}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'mm/dd/yyyy',
                      }}
                      error={fieldHasError('dateOfBirth')}
                      helperText={getFieldHelperText('dateOfBirth')}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card variant='outlined' sx={userDetailsSx.card}>
        <CardContent sx={userDetailsSx.cardContent}>
          <Typography variant='h6' sx={{ mt: 0, mb: 2 }}>
            {t('Outpatient-Begin-BabyBornPrompt')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl sx={{ mt: 2.3 }}>
                <RadioGroup
                  name='isActualBaby'
                  defaultValue={formikObj.values.isActualBaby}
                  {...formikObj.getFieldProps('isActualBaby')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => [
                    formikObj.handleChange({
                      target: {
                        value: e.target.value,
                        name: 'isActualBaby',
                      },
                    }),
                    onHandleChange,
                  ]}
                >
                  <Grid container spacing={1} sx={{ mt: 0, ml: 0 }}>
                    <FormControlLabel
                      value='true'
                      control={
                        <Radio
                          sx={{
                            color: '#8a9cad',
                            '&.Mui-checked': {
                              color: '#e6863e',
                            },
                          }}
                        />
                      }
                      label={t('Outpatient-Begin-BabyBornYesAnswer')}
                      sx={{ pr: 4, pt: 0 }}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          sx={{
                            color: '#8a9cad',
                            '&.Mui-checked': {
                              color: '#e6863e',
                            },
                          }}
                        />
                      }
                      label={t('Outpatient-Begin-BabyBornNoAnswer')}
                      value='false'
                      sx={{ pr: 4, pt: 0 }}
                    />
                  </Grid>
                  {formikObj.touched.isActualBaby &&
                    formikObj.errors.isActualBaby && (
                      <span
                        style={{
                          color: '#d32f2f',
                          fontFamily: 'Roboto ,Helvetica, Arial, sans-serif',
                          fontWeight: 400,
                          fontSize: '0.75rem',
                          lineHeight: 1.66,
                          letterSpacing: '0.03333em',
                          textAlign: 'left',
                          marginTop: '3px',
                          marginRight: '14px',
                          marginBottom: 0,
                          marginLeft: '14px',
                        }}
                      >
                        {getFieldHelperText('isActualBaby')}
                      </span>
                    )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel sx={userDetailsSx.inputLabel}>
                {formikObj.values.isActualBaby === 'false'
                  ? t('Outpatient-Begin-BabyDueDatePrompt')
                  : t('Outpatient-Begin-BabyDOBPrompt')}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  mask='__/__/____'
                  disableFuture={formikObj.values.isActualBaby === 'true'}
                  disablePast={formikObj.values.isActualBaby === 'false'}
                  components={{
                    OpenPickerIcon: CalendarMonthOutlinedIcon,
                  }}
                  inputFormat='MM/dd/yyyy'
                  value={formikObj.values.childDateOfBirth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | null) =>
                    formikObj.handleChange({
                      target: {
                        value: e,
                        name: 'childDateOfBirth',
                      },
                    })
                  }
                  disableHighlightToday
                  shouldDisableDate={disableToday}
                  renderInput={(params) => (
                    <TextField
                      {...formikObj.getFieldProps('childDateOfBirth')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        formikObj.handleChange({
                          target: {
                            value: e,
                            name: 'childDateOfBirth',
                          },
                        })
                      }
                      fullWidth
                      {...params}
                      sx={userDetailsSx.datePicker}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'mm/dd/yyyy',
                      }}
                      error={fieldHasError('childDateOfBirth')}
                      helperText={getFieldHelperText('childDateOfBirth')}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={userDetailsSx.card}>
        <CardContent sx={userDetailsSx.cardContent}>
          <Typography variant='h6' sx={{ mt: 0, mb: 2 }}>
            {t('NewOP-MomDetails-ShippingLabel')}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel sx={userDetailsSx.inputLabel}>
                {t('Orders-Details-Street1')}
              </InputLabel>
              <TextField
                {...formikObj.getFieldProps('street1')}
                error={fieldHasError('street1')}
                helperText={getFieldHelperText('street1')}
                placeholder={t('Orders-Details-Street1')}
                {...inputFiledProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel sx={userDetailsSx.inputLabel}>
                {t('Orders-Details-Street2')}
              </InputLabel>
              <TextField
                {...formikObj.getFieldProps('street2')}
                placeholder={t('Orders-Details-Street2')}
                {...inputFiledProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack>
                <InputLabel sx={userDetailsSx.inputLabel}>
                  {t('Patient-Details-ContactAddressCityPtompt')}
                </InputLabel>
                <TextField
                  {...inputFiledProps}
                  name='city'
                  {...formikObj.getFieldProps('city')}
                  error={fieldHasError('city')}
                  helperText={getFieldHelperText('city')}
                  placeholder={t('Patient-Details-ContactAddressCityPtompt')}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack>
                <InputLabel sx={userDetailsSx.inputLabel}>
                  {t('Patient-Details-BillingAddressStatePrompt')}
                </InputLabel>
                <Autocomplete
                  id='free-solo-2-demo'
                  disableClearable
                  options={stateNames
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((option) => ({
                      key: `${option.title}
                       (${option.shortCode})
                      `,
                      value: option.title,
                      label: option.shortCode,
                    }))}
                  filterOptions={filterOptions}
                  autoHighlight
                  autoSelect={isState}
                  selectOnFocus
                  value={formikObj.values.state}
                  onChange={(e, val) =>
                    formikObj.handleChange({
                      target: { value: val.value, name: 'state' },
                    })
                  }
                  onInput={(val: object | any) => {
                    formikObj.handleChange({
                      target: { value: val.value, name: 'state' },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t(
                        'Patient-Details-BillingAddressStatePrompt'
                      )}
                      {...formikObj.getFieldProps('state')}
                      error={fieldHasError('state')}
                      helperText={getFieldHelperText('state')}
                      autoSelect={isState}
                      onChange={(e) => {
                        formikObj.handleChange({
                          target: {
                            value: getStateName(e.target.value),
                            name: 'state',
                          },
                        });
                      }}
                      onClick={() => {
                        formikObj.handleChange({
                          target: {
                            value: getStateName(''),
                            name: 'state',
                          },
                        });
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment
                            sx={{ mb: 1, mt: 0 }}
                            position='start'
                          >
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        sx: userDetailsSx.input,
                        type: 'search',
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel sx={userDetailsSx.inputLabel}>
                {t('Patient-Details-BillingAddressZipPrompt')}
              </InputLabel>
              <TextField
                type='number'
                inputProps={{ inputMode: 'numeric', maxlength: 5 }}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value
                    .replace(/[^0-9]/g, '')
                    .toString()
                    .slice(0, 5);
                }}
                {...inputFiledProps}
                value={formikObj.values.zip}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  formikObj.handleChange({
                    target: {
                      value: getStateName(e.target.value),
                      name: 'zip',
                    },
                  })
                }
                error={fieldHasError('zip')}
                helperText={getFieldHelperText('zip')}
                placeholder={t('Patient-Details-BillingAddressZipPrompt')}
                sx={{
                  '& input[type=number]': {
                    '-moz-appearance': 'textfield',
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Card>
  );
};
