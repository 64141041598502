import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Icon,
  CardActionArea,
  CardMedia,
  InputAdornment,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CreateOrderStepProps } from '../../CreateOrderStepProps';
import { getPumpListByType } from '../../../../services/ProductService';
import { Pump as PumpType } from '../../../../models/Pump';
import { CreateOrderRequest } from '../../../../models/CreateOrderRequest';

const cardStyle = {
  cardMain: {
    boxShadow: 'none',
    overflow: 'auto',
    width: '100%',
    background: 'transparent',
    mt: 1.87,
  },
  card: {
    border: '0',
    padding: '10px',
    background: 'transparent',
    boxShadow: 'none !important',
  },
  cardContent: {
    paddingBottom: '24px',
    border: '0px',
    boxShadow: '0 0 10px 0px #ddd',
    borderRadius: '15px',
    background: '#fff',
  },
  uiFormControlRoot: {
    width: '100%',
  },
  inputLabel: {
    color: '#4A6B89',
    fontSize: '12px',
    margin: '0 0 5px ',
    width: '100%',
  },
  textField: {
    border: '0',
    margin: '0',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#899dad',
      },
      '&:hover fieldset': {
        borderColor: '#899dad',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#899dad ',
      },
    },
  },
  input: {
    color: '#899dad',
    fontSize: '13px',
    backgroundColor: '#F5F6FA',
    fontWeight: '400 ',
    height: '40px ',
    margin: '0',
    width: '100%',
    maxWidth: '100%',

    '.MuiAutocomplete-input': {
      padding: '0 !important',
    },
  },
  subCard: {
    height: '100%',
    mt: 0,
    mb: 0,
    background: '#f5f6fa',
    boxShadow: '0 4px 7px 1px #dcdcdc',
    marginTop: '0px',
    marginBottom: '0px',
    borderRadius: '10px',
  },
  typoGraphy: {
    textAlign: 'center',
    color: '#4a6b89',
  },
};
interface PumpProps {
  id: number | undefined;
  description: string;
  medicaidEligible: boolean;
  make: string;
  model: string;
  imageUrl: string;
}
export const Pump: React.FunctionComponent<CreateOrderStepProps> = ({
  formikObj,
}) => {
  const { t } = useTranslation();
  const [pumps, setPumps] = useState<PumpType[] | []>([]);
  const [searchPumpList, setSearchPumpList] = useState('');
  const handleSelectPump = (pump: PumpProps) => {
    if (formikObj.values.productId === pump.id) {
      formikObj.handleChange({
        target: { value: '', name: 'productId' },
      });
      formikObj.setFieldValue(`product`, null, false);
    } else {
      formikObj.handleChange({
        target: { value: pump.id, name: 'productId' },
      });
      formikObj.setFieldValue(`product`, pump, false);
    }
  };
  const getFieldHelperText = (
    field: keyof CreateOrderRequest
  ): string | undefined =>
    formikObj.touched[field] ? formikObj.errors[field] : undefined;

  const getPumpList = async () => {
    try {
      const data = await getPumpListByType(
        0,
        formikObj.values.isMedicaid === 'true' ? 'Medicaid' : 'Commercial'
      );
      setPumps(data);
    } catch (error) {
      // TODO handle error
    }
  };
  const pumpMatchesSearchQuery = (value: string) =>
    value.toLowerCase().indexOf(searchPumpList.toLowerCase()) !== -1;

  const filteredPumps = pumps.filter(
    (pump) =>
      pumpMatchesSearchQuery(t(`ProductCatalogItemDescription${pump.id}`)) ||
      pumpMatchesSearchQuery(pump.model) ||
      pumpMatchesSearchQuery(pump.make)
  );

  useEffect(() => {
    getPumpList();
  }, []);
  const handleArrowKeyPress = (keyCode: number) => {
    filteredPumps.map((item: PumpType, index: number) => {
      if (formikObj.values.productId === item.id) {
        if (keyCode === 39) {
          if (filteredPumps.length > index + 1) {
            window.scrollBy(0, 80);
            formikObj.handleChange({
              target: { value: pumps[index + 1].id, name: 'productId' },
            });
            formikObj.setFieldValue(`product`, pumps[index + 1], false);
          } else {
            window.scrollTo(50, 0);
            formikObj.handleChange({
              target: { value: pumps[0].id, name: 'productId' },
            });
            formikObj.setFieldValue(`product`, pumps[0], false);
          }
        }
        if (keyCode === 37) {
          window.scrollBy(0, -60);
          formikObj.handleChange({
            target: { value: pumps[index - 1].id, name: 'productId' },
          });
          formikObj.setFieldValue(`product`, pumps[index - 1], false);
        }
      }
      return item;
    });
  };
  const handleBlurFunction = () => {
    (document.activeElement as HTMLElement).blur();
  };
  return (
    <Card
      sx={cardStyle.cardMain}
      onTouchStart={() => {
        handleBlurFunction();
      }}
    >
      <Card variant='outlined' sx={cardStyle.card}>
        <CardContent sx={cardStyle.cardContent}>
          <Typography variant='h6' sx={{ mt: 0, mb: 2 }}>
            {t('OutPatient-SelectProduct-SectionTitle')}
          </Typography>
          <Grid container sx={{ mt: 2, mb: 0, flexDirection: 'column' }}>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                sx={{
                  minWidth: 120,
                  textAlign: 'left',
                  mt: 0,
                  outline: 0,
                  backgroundColor: '#f5f6fa',
                  Input: { py: 1.11, px: 0.6 },
                }}
                onChange={(e) => setSearchPumpList(e.target.value)}
                value={searchPumpList}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon>
                        <SearchIcon />
                      </Icon>
                    </InputAdornment>
                  ),
                  endAdornment: searchPumpList && (
                    <InputAdornment
                      position='end'
                      onClick={() => setSearchPumpList('')}
                    >
                      <Icon>
                        <CloseIcon sx={{ fontSize: '15px' }} />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                autoComplete='false'
                type='text'
                placeholder={t('SearchPrompt')}
              />
            </Grid>
            <Grid item>
              {formikObj.errors.productId && (
                <span
                  style={{
                    color: '#d32f2f',
                    fontFamily: 'Roboto ,Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 1.66,
                    letterSpacing: '0.03333em',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginRight: '14px',
                    marginBottom: 0,
                    marginLeft: '14px',
                  }}
                >
                  {getFieldHelperText('productId')}
                </span>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ mt: 2, mb: 0 }}>
            {filteredPumps.length > 0 ? (
              filteredPumps.map((item: PumpType) => (
                <Grid item xs={12} sm={4} md={4} key={item.id}>
                  <Card
                    sx={{
                      ...cardStyle.subCard,
                      outline: `${
                        formikObj.values.productId === item.id
                          ? '2px solid #e6863e'
                          : 'none'
                      }`,
                    }}
                    onClick={() => handleSelectPump(item)}
                    onKeyDown={(e) => handleArrowKeyPress(e.keyCode)}
                  >
                    <CardActionArea sx={{ height: '100%', padding: '2px' }}>
                      {formikObj.values.productId === item.id && (
                        <Button
                          style={{ color: '#e6863e', position: 'absolute' }}
                          startIcon={<CheckCircleIcon />}
                        />
                      )}
                      <CardMedia
                        component='img'
                        height='250'
                        image={item.imageUrl}
                        alt='pump item'
                        sx={{
                          backgroundColor: '#fff',
                          objectFit: 'scale-down',
                        }}
                      />
                      <CardContent sx={{ p: '30px 16px', height: '100%' }}>
                        <Typography
                          gutterBottom
                          variant='h5'
                          component='div'
                          sx={{
                            ...cardStyle.typoGraphy,
                            fontSize: '16px',
                            fontWeight: '600',
                          }}
                        >
                          {item.model}
                        </Typography>
                        <Typography
                          variant='body2'
                          color='text.secondary'
                          sx={cardStyle.typoGraphy}
                        >
                          {`${t('OutPatient-SelectProduct-AccessoryFee')}: $${
                            item.salePrice
                          }`}
                        </Typography>
                        <Typography
                          variant='body2'
                          color='text.secondary'
                          sx={cardStyle.typoGraphy}
                        >
                          {t(`ProductCatalogItemDescription${item.id}`)
                            .split('\n')
                            .map((str, index) => (
                              <p key={`${index.toString()}`}>{str}</p>
                            ))}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid
                container
                spacing={3}
                sx={{
                  mt: 2,
                  mb: 0,
                  ml: 3,
                  fontSize: '15px',
                  justifyContent: 'center',
                }}
              >
                No pump(s) available
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Card>
  );
};
