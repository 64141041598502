import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  SelectChangeEvent,
  Step,
  Stepper,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FocusError } from 'focus-formik-error';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { array, date, lazy, mixed, object, string } from 'yup';
import { Form, Formik, useFormik } from 'formik';
import { I18nLanguage } from '../../common/utils/Constants';
import { i18nLanguageCodeToName } from '../../common/utils/Utils';
import { UserDetails } from './Steps/UserDetails/UserDetails';
import { Signature } from './Steps/Signature/Signature';
import { Provider } from './Steps/Provider/Provider';
import { Pump } from './Steps/Pump/Pump';
import { Review } from './Steps/Review/Review';
import { theme } from '../../common/utils/Theme';
import { createOrder } from '../../services/OrderService';
import { CreateOrderStepProps } from './CreateOrderStepProps';
import { CreateOrderRequest } from '../../models/CreateOrderRequest';
import 'react-toastify/dist/ReactToastify.css';
import { stateNames } from '../../utils/Constants';

const notifySuccess = (message: string) => {
  toast.success(message, { position: 'top-center', autoClose: 1000 });
};

const notifyError = (message: string) => {
  toast.error(message, { position: 'top-center' });
};

const stepComponents = [UserDetails, Provider, Pump, Signature, Review];
const languageStep = 0;
const reviewStep = stepComponents.length - 1;

interface FileProps {
  type: string;
  fileName: string;
  encodedImg: string;
  file: File;
  isLoading: boolean;
  index: number;
  isDelete: boolean;
}
export const CreateOrder: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const steps = [
    t('NewOP-ReviewPage-PatientDetails'),
    t('NewOP-SelectProvider-DoctorInsurance'),
    t('NewOP-SelectPump-SelectPump'),
    t('NewOP-ReviewPage-Signature'),
  ];

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(languageStep);
  const [previousStep, setPreviousStep] = useState<number>(-1);
  const [loading, setLoading] = useState(false);
  const phone: string | any = secureLocalStorage.getItem('phoneNumber');
  const language = i18nLanguageCodeToName(i18n.language as I18nLanguage);
  const { state } = useLocation();
  const isLogin = secureLocalStorage.getItem('isLogin');
  useEffect(() => {
    if (!state && !isLogin) {
      navigate('/orders/language');
    }
  }, []);
  const isLastStep = () => activeStep === stepComponents.length - 1;
  const [createOrderRequest, setCreateOrderRequest] =
    useState<CreateOrderRequest>(new CreateOrderRequest());

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = () => {
    secureLocalStorage.clear();
    window.location.replace('https://form.jotform.com/ursmedical/compsox');
  };
  const userDetailsSchema = object({
    firstName: string().required(t('OutPatient-Begin-FirstNameError')),
    lastName: string().required(t('OutPatient-Begin-LastNameError')),
    dateOfBirth: date()
      .typeError(t('OutPatient-Begin-DateOfBirthError'))
      .max(new Date(), t('InvalidBabyBirthDatePrompt'))
      .min(new Date('1950/01/01'), t('InvalidBabyBirthDatePrompt'))
      .required(t('DateOfBirthNull')),
    isActualBaby: string().required(t('NewOP-MomDetails-Required')),
    childDateOfBirth: date()
      .typeError(t('InvalidBabyBirthDatePrompt'))
      .required(t('InvalidBabyBirthDatePrompt'))
      .when(['isActualBaby'], (isActualBaby, schema) =>
        isActualBaby === 'true'
          ? schema
              .max(new Date(), t('InvalidBabyBirthDatePrompt'))
              .min(
                moment().subtract(6, 'months').format(),
                t('InvalidBabyBirthDatePrompt')
              )
          : schema
              .min(new Date(), t('InvalidBabyBirthDatePrompt'))
              .max(
                moment().add(9, 'months').format(),
                t('InvalidBabyBirthDatePrompt')
              )
      ),
    street1: string().required(t('EmptyAddressLine1Prompt')),
    city: string().required(t('EmptyCityPrompt')),
    state: string()
      .oneOf(
        [null, ...stateNames.map((option: object | any) => option.title)],
        t('OutPatient-ProvideShippingInformation-StateError')
      )
      .required(t('OutPatient-ProvideShippingInformation-StateError')),
    zip: string()
      .required(t('PatientBillingAddressZipException'))
      .length(5, t('PatientBillingAddressZipException')),
  });
  const providerSchema = object({
    providerName: string().required(
      t('OutPatient-SelectProvider-NoProviderSelected')
    ),
    insuranceName: string().when(
      ['insuranceAttachments'],
      (insuranceAttachments, schema) =>
        insuranceAttachments?.filter(
          (file: FileProps) => file?.isDelete === false
        ).length === 0
          ? schema.required(
              t('OutPatient-ProvideInsurance-EnterInsuranceNamePrompt')
            )
          : schema
    ),
    insurancePolicyNumber: string().when(
      ['insuranceAttachments'],
      (insuranceAttachments, schema) =>
        insuranceAttachments?.filter(
          (file: FileProps) => file?.isDelete === false
        ).length === 0
          ? schema.required(
              t('OutPatient-ProvideInsurance-PrimaryInsuranceNumberError')
            )
          : schema
    ),
    isMedicaid: string().required(t('EmptyMedicaidPrompt')),
    insuranceAttachments: lazy(() =>
      mixed().when(['insurancePolicyNumber', 'insuranceName'], {
        is: (insurancePolicyNumber: string, insuranceName: string) =>
          !insurancePolicyNumber && !insuranceName,
        then: mixed()
          .required(t('NewOP-Insurance-UploadCard'))
          .test(
            'require',
            t('NewOP-Insurance-UploadCard'),
            (value) =>
              value?.filter((file: FileProps) => file?.isDelete === false)
                .length !== 0
          ),
        otherwise: mixed(),
      })
    ),
  });

  const pumpSchema = object({
    productId: string().required(t('NewOP-SelectPump-Required')),
  });
  const signatureSchema = object({
    signatureEncodedImg: string().required(
      t('InPatient-ConfirmSignature-ProvideSignaturePrompt')
    ),
  });
  const validationSchema = [
    userDetailsSchema,
    providerSchema,
    pumpSchema,
    signatureSchema,
  ];
  const formik = useFormik({
    initialValues: new CreateOrderRequest(),
    validationSchema: validationSchema[activeStep],
    onSubmit: async () => {
      if (previousStep === activeStep) {
        setActiveStep(stepComponents.length - 1);
      } else {
        setActiveStep(activeStep + 1);
      }
    },
  });
  const onHandleChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) =>
    setCreateOrderRequest((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  const onOrderRequestChange = (name: string, value: string) =>
    setCreateOrderRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  const renderStep = (step: number) => {
    const StepComponent = stepComponents[step];

    const stepCompProps: CreateOrderStepProps = {
      onHandleChange,
      createOrderRequest,
      onOrderRequestChange,
      setActiveStep,
      setPreviousStep,
    };
    stepCompProps.formikObj = formik;

    return <StepComponent {...stepCompProps} />;
  };

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ ownerState }) => ({
      color:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#784af4',
      }),
      '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
      },
      '& .QontoStepIcon-box': {
        width: 22,
        height: 24,
        borderRadius: '2px',
        ...(ownerState.active
          ? {
              background: '#e6863e',
            }
          : {
              background: '#01325e',
            }),
        color: '#fff',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        textAlign: 'center',
        fontSize: '13px',
        lineHeight: '22px',
      },
    })
  );

  const createOrderSx = {
    stpper: {
      padding: '10px',
      '.MuiStepConnector-horizontal': {
        display: 'none',
      },
      '.MuiStep-horizontal': {
        pl: 0,
        pr: 1,
        width: '33.3333%',
        borderBottom: ' 2px solid #4a6b89',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        '.MuiStepLabel-label': {
          display: 'none',
        },
        '.active-order-step .MuiStepLabel-label': {
          display: 'block!important',
          ml: 1,
        },
        '.MuiStep-horizontal': {
          width: 'inherit',
        },
        '.active-order-step': {
          width: '100%',
        },
        '.MuiStepLabel-iconContainer': {
          pr: 0,
        },
        '.order-stepper:last-of-type': {
          p: 0,
        },
      },
    },
    stpperBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footerMain: {
      pt: 2,
      mb: 10,
      position: 'fixed',
      left: 0,
      bottom: 0,
      margin: 0,
      width: '100%',
      padding: '10px 30px',
      background: ' #fff',
      justifyContent: 'end',
      gap: '10px',
      boxShadow: '0 0 6px 0 #ddd',
    },
    footerBox: {
      display: 'inline-flex',
      flexDirection: 'row',
      padding: '0 10px',
      float: 'right',
      gap: '10px',
    },
    footerButton: {
      background: ' #e7eef7',
      boxShadow: 'none',
      borderRadius: '5px',
      border: '0',
      fontSize: '14px',
      padding: '10px 26px',
      lineHeight: 'normal',
      textTransform: 'capitalize',
      color: 'white',
      display: 'block',
      margin: 'auto',
      marginRight: 0,
    },
  };

  const StepIcon = (props: StepIconProps) => {
    const { active, className, icon } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        <div className='QontoStepIcon-box'>{icon}</div>
      </QontoStepIconRoot>
    );
  };
  return (
    <>
      <Formik
        initialValues={CreateOrderRequest}
        onSubmit={async (values, { setSubmitting }) => {
          if (activeStep === 2) {
            if (formik.values.productId.length === 0) {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
          }
          setCreateOrderRequest({ ...createOrderRequest, ...formik.values });
          if (!isLastStep()) {
            setSubmitting(false);
            if (typeof formik.errors !== null) {
              setSubmitting(false);
              formik.handleSubmit();
            } else {
              setActiveStep(activeStep + 1);
            }
          } else {
            try {
              setLoading((prevLoading) => !prevLoading);
              const val = {
                ...createOrderRequest,
                dateOfBirth: moment(formik.values.dateOfBirth).format(
                  'yyyy-MM-DD'
                ),
                state: formik.values.state.split(' ').join('_'),
                notes: [
                  formik.values.providerId === '' ||
                  formik.values.providerId === null
                    ? `New Provider = ${formik.values.providerName}`
                    : '',
                  `New Insurance Payer = ${formik.values.insuranceName}`,
                ],
                dueDate:
                  formik.values.isActualBaby === 'true'
                    ? ''
                    : moment(formik.values.childDateOfBirth).format(
                        'yyyy-MM-DD'
                      ),
                childDateOfBirth:
                  formik.values.isActualBaby === 'true'
                    ? moment(formik.values.childDateOfBirth).format(
                        'yyyy-MM-DD'
                      )
                    : '',
                medicaidInsuranceNumber:
                  formik.values.isMedicaid === 'true'
                    ? formik.values.insurancePolicyNumber
                    : '',
                insurancePolicyNumber:
                  formik.values.isMedicaid === 'false'
                    ? formik.values.insurancePolicyNumber
                    : '',
                phoneNumber: phone?.slice(2) || '',
                language,
                files: [
                  ...createOrderRequest.prescriptionAttachments.filter(
                    (file: FileProps | any) => file?.isDelete === false
                  ),
                  ...createOrderRequest.insuranceAttachments.filter(
                    (file: FileProps | any) => file?.isDelete === false
                  ),
                ],
              };
              const {
                insuranceAttachments,
                prescriptionAttachments,
                providerName,
                physician,
                product,
                isActualBaby,
                insuranceId,
                ...data
              } = val;
              const blobFile = await fetch(
                `data:image/png;base64,${data?.signatureEncodedImg}`
              ).then((res) => res.blob());
              const signatureFile = new File([blobFile], 'signature.png', {
                type: 'image/png',
              });
              const formData: any = new FormData();
              formData.append('firstName', data?.firstName);
              formData.append('lastName', data?.lastName);
              formData.append('dateOfBirth', data?.dateOfBirth);
              formData.append('phoneNumber', data?.phoneNumber);
              formData.append('language', data?.language);
              formData.append('childDateOfBirth', data?.childDateOfBirth);
              formData.append('dueDate', data?.dueDate);
              formData.append('street1', data?.street1);
              formData.append('street2', data?.street2);
              formData.append('city', data?.city);
              formData.append('state', data?.state);
              formData.append('zip', data?.zip);
              formData.append(
                'providerId',
                data?.providerId !== null ? data?.providerId : ''
              );
              formData.append('isMedicaid', data?.isMedicaid);
              formData.append(
                'medicaidInsuranceNumber',
                data?.medicaidInsuranceNumber
              );
              formData.append('insuranceName', data?.insuranceName);
              formData.append(
                'insurancePolicyNumber',
                data?.insurancePolicyNumber
              );
              data?.files?.map(async (resp: FileProps | any, index: number) => {
                formData.append(`files[${index}].type`, resp?.type);
                formData.append(`files[${index}].file`, resp?.file);
              });
              formData.append('productId', data?.productId);
              data?.notes?.map((resp, index) =>
                formData.append(`notes[${index}]`, resp)
              );
              formData.append('signatureFile', signatureFile);
              await createOrder(formData);
              setLoading((prevLoading) => !prevLoading);
              notifySuccess(t('OutPatient-OrderComplete-ThankYou'));
              handleSubmit();
            } catch (error: any) {
              notifyError(error.response.data.message);
              setLoading((prevLoading) => !prevLoading);
            }
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FocusError formik={formik} />
            <Box
              sx={{
                width: '100%',
                mt: 3,
                pb: 7,
              }}
            >
              {activeStep !== reviewStep && (
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  sx={createOrderSx.stpper}
                >
                  {steps.map((label, index) => (
                    <Step
                      key={label}
                      className={
                        activeStep === index
                          ? 'active-order-step order-stepper'
                          : 'order-stepper'
                      }
                    >
                      <StepLabel StepIconComponent={StepIcon}>
                        <Grid
                          component='span'
                          sx={{
                            color: `${
                              activeStep === index ? '#e6863e' : '#01325e'
                            }`,
                          }}
                        >
                          {label}
                        </Grid>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
              <Box sx={createOrderSx.stpperBox}>{renderStep(activeStep)}</Box>
              <Box sx={createOrderSx.footerMain}>
                <Container component='main' maxWidth='lg'>
                  <Box sx={createOrderSx.footerBox}>
                    {activeStep !== languageStep && (
                      <Button
                        sx={{
                          ...createOrderSx.footerButton,
                          color: '#4a6b89 !important',
                          '&:hover': {
                            background: ' #e7eef7',
                            color: '#4a6b89 !important',
                            boxShadow: 'none',
                          },
                        }}
                        onClick={handleBack}
                      >
                        {`${t('BackPagePrompt')}`}
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      sx={{
                        ...createOrderSx.footerButton,
                        background: ' #e6863e',
                        '&:hover': {
                          background: ' #e6863e',
                          boxShadow: 'none',
                        },
                      }}
                      disabled={isSubmitting || loading}
                      type='submit'
                    >
                      {isLastStep()
                        ? `${t('SubmitPrompt')}`
                        : `${t('NextPrompt')}`}
                    </Button>
                  </Box>
                </Container>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {loading && (
        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: ' 30px 0',
            overflowY: 'auto',
            '.input-error': {
              border: '1px solid #d32f2f!important',
            },
          }}
          open={loading}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            sx={{
              borderRadius: 4,
              position: 'relative',
              width: 800,
              maxWidth: '90%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 3,
              overflowY: 'auto',
              margin: 'auto',
              outline: 'none',
            }}
          >
            <CircularProgress sx={{ color: '#E6863E' }} />
          </Box>
        </Modal>
      )}
    </>
  );
};
