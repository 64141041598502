import {
  AppBar,
  Container,
  CssBaseline,
  SxProps,
  Theme,
  ThemeProvider,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import { badge, theme } from '../../utils/Theme';
import logo from '../../../assets/images/favicon.png';

interface AppLayoutProps {
  appName: string;
  children?: ReactNode;
}

const appBarSx: SxProps<Theme> = {
  position: 'relative',
  borderBottom: (t) => `1px solid ${t.palette.divider}`,
};

export const AppLayout: FunctionComponent<AppLayoutProps> = ({
  appName,
  children,
}) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ToastContainer />
    <Box
      sx={
        process.env.REACT_APP_CUR_ENV === 'DEV' ||
        process.env.REACT_APP_CUR_ENV === 'UAT'
          ? badge
          : null
      }
    >
      <AppBar
        className='badge'
        position='absolute'
        color='default'
        elevation={0}
        sx={appBarSx}
      >
        <Container component='main' maxWidth='lg'>
          <Box
            component='div'
            sx={{
              display: 'flex',
              alignItems: 'center',
              py: 1.5,
              px: 0,
            }}
          >
            <Grid
              component='img'
              src={logo}
              sx={{
                width: '30px',
                height: '30px',
                color: '#e6863e',
                mr: 1,
              }}
            />
            <Typography
              sx={{
                color: '#01325e',
                fontSize: '25px',
              }}
              variant='h4'
            >
              {appName}
            </Typography>
          </Box>
        </Container>
      </AppBar>
      <Container component='main' maxWidth='lg'>
        {children}
      </Container>
    </Box>
  </ThemeProvider>
);
