import Axios from 'axios';
import { apiUrl, apiVersion } from '../utils/Constants';
import { Provider } from '../models/Provider';
import { Insurance } from '../models/Insurance';
import { CreateOrderProps } from '../models/CreateOrderRequest';

export const providerEndpoint = `${apiUrl}/api/v${apiVersion}/outpatient/providers`;
export const insuranceEndpoint = `${apiUrl}/api/v${apiVersion}/outpatient/insurances`;
export const ordersEndpoint = `${apiUrl}/api/v${apiVersion}/orders/outPatient`;
export const getAllProviders = async () => {
  const { data } = await Axios.get<Provider[]>(providerEndpoint);
  return data;
};

export const getInsurancesByType = async (type: string) => {
  const { data } = await Axios.get<Insurance[]>(
    `${insuranceEndpoint}/type?type=${type}`
  );
  return data;
};
export const createOrder = async (request: CreateOrderProps) => {
  const { data } = await Axios.post(ordersEndpoint, request);
  return data;
};
