import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: '#6a859e',
          textAlign: 'left',
          borderRadius: 1.25,
          backgroundColor: '#f5f6fa',
          width: '100%',
          height: 'fit-content',
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
  },
});

export const badge = {
  ' & .badge:first-child:before': {
    content: `'${process.env.REACT_APP_CUR_ENV}'`,
    zIndex: 0,
    color: '#fff',
    fontSize: '18px',
    lineHeight: '30px',
    fontWeight: 'bold',
    overflow: 'hidden',
    transform: 'rotate(50deg)',
    width: '120px',
    display: 'flex',
    background: '#d32f2f',
    backGround: 'linear-gradient(#d32f2f 0%, #d32f2f 100%)',
    boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
    position: 'fixed',
    top: '27px',
    right: '-19px',
    justifyContent: 'center',
    clipPath:
      'polygon(120px 20px,90px -10px,30px -10px, 0px 20px,10px 30px,110px 30px)',
    height: '30px',
  },
  ' & .badge:first-child:after': {
    content: `'${process.env.REACT_APP_CUR_ENV}'`,
    zIndex: 0,
    justifyContent: 'center',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '30px',
    overflow: 'hidden',
    transform: 'rotate(50deg)',
    width: '120px',
    display: 'flex',
    background: '#d32f2f',
    backGround: 'linear-gradient(#d32f2f 0%, #d32f2f 100%)',
    boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
    position: 'fixed',
    top: '27px',
    right: '-19px',
    clipPath:
      'polygon(120px 20px,90px -10px,30px -10px, 0px 20px,10px 30px,110px 30px)',
    height: '30px',
  },
};
