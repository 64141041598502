import { Box, Button, Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import image from '../../../../assets/images/baby-mom.jpg';
import logo from '../../../../assets/images/logo-bpd.png';
import { SupportedLanguage } from '../../../../common/utils/Constants';
import { badge } from '../../../../common/utils/Theme';
import { changeI18nLanguage } from '../../../../common/utils/Utils';

const btnStyle = {
  color: '#1d3346',
  m: 3,
  backgroundColor: '#f5f6fa',
  textTransform: 'initial',
  borderRadius: 2,
  px: 6.5,
  py: 2.5,
  fontSize: 16,
  fontWeight: 600,
  '&:hover': {
    background: '#e7eef7 ',
  },
};
export const Language: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLanguageChange = (language: SupportedLanguage) => {
    secureLocalStorage.setItem('language', language);
    changeI18nLanguage(language);
    navigate('/orders/phone');
  };
  const details = navigator.userAgent;
  const regExp = /android|iphone/i;
  const isMobileDevice = regExp.test(details);
  return (
    <Box
      sx={
        process.env.REACT_APP_CUR_ENV === 'DEV' ||
        process.env.REACT_APP_CUR_ENV === 'UAT'
          ? badge
          : null
      }
    >
      <Box
        className='badge'
        sx={{
          width: '100%',
          display: 'block',
          m: 'auto',
          textAlign: 'center',
          overflow: 'hidden',
        }}
      >
        <Grid
          container
          columnSpacing={2}
          sx={{ ml: -1, minHeight: { xs: 400, sm: '100vh' } }}
        >
          <Grid
            item
            xs={6}
            sx={{
              p: 0,
              width: '-webkit-fill-available',
              backgroundImage: `url(${image})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'top',
              display: { xs: 'none', sm: 'block' },
              '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
                display: isMobileDevice ? 'none' : 'block',
              },
            }}
          />
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              p: 0,
              pr: '10px !important',
              m: 0,
              pl: '8px !important',
              backgroundColor: 'white',
              '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
                flexBasis: isMobileDevice ? '100%' : '50%',
                minWidth: isMobileDevice ? '100%' : '50%',
              },
            }}
          >
            <Grid sx={{ mt: 6 }}>
              <Grid
                component='img'
                src={logo}
                sx={{ width: '100%', maxWidth: '400px' }}
              />
              <Typography variant='h5' sx={{ m: 2, color: '#4a6b89' }}>
                {t('NewOP-Welcome-HaveInsurance')}
              </Typography>
            </Grid>

            <Grid>
              <Typography
                variant='h6'
                sx={{ pt: 6, mt: 3, mb: 2, fontWeight: 600 }}
              >
                {t('NewOP-Welcome-SelectLanguage')}
              </Typography>
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={btnStyle}
                onClick={() => handleLanguageChange('English')}
              >
                English
              </Button>
              <Button
                sx={btnStyle}
                onClick={() => handleLanguageChange('Espanol')}
              >
                Espanol
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
