export const appName = 'Breast Pump Depot';
export const apiUrl = process.env.REACT_APP_API_URL;
export const apiVersion = process.env.REACT_APP_API_VERSION;
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const sentrySampleRate = process.env.REACT_APP_SENTRY_SAMPLE_RATE;
export const sentryEnvironment = process.env.REACT_APP_SENTRY_ENV;

export const cityNames = [
  { title: 'New York' },
  { title: 'Los Angeles' },
  { title: 'Chicago' },
  { title: 'Houston' },
  { title: 'Phoenix' },
  { title: 'Philadelphia' },
  { title: 'San Antonio' },
];

export const stateNames = [
  { title: 'ALABAMA', shortCode: 'AL' },
  { title: 'ALASKA', shortCode: 'AK' },
  { title: 'ARIZONA', shortCode: 'AZ' },
  { title: 'ARKANSAS', shortCode: 'AR' },
  { title: 'CALIFORNIA', shortCode: 'CA' },
  { title: 'COLORADO', shortCode: 'CO' },
  { title: 'CONNECTICUT', shortCode: 'CT' },
  { title: 'DELAWARE', shortCode: 'DE' },
  { title: 'FLORIDA', shortCode: 'FL' },
  { title: 'GEORGIA', shortCode: 'GA' },
  { title: 'HAWAII', shortCode: 'HI' },
  { title: 'IDAHO', shortCode: 'ID' },
  { title: 'ILLINOIS', shortCode: 'IL' },
  { title: 'INDIANA', shortCode: 'IN' },
  { title: 'IOWA', shortCode: 'IA' },
  { title: 'KANSAS', shortCode: 'KS' },
  { title: 'KENTUCKY', shortCode: 'KY' },
  { title: 'LOUISIANA', shortCode: 'LA' },
  { title: 'MAINE', shortCode: 'ME' },
  { title: 'MARYLAND', shortCode: 'MD' },
  { title: 'MASSACHUSETTS', shortCode: 'MA' },
  { title: 'MICHIGAN', shortCode: 'MI' },
  { title: 'MINNESOTA', shortCode: 'MN' },
  { title: 'MISSISSIPPI', shortCode: 'MS' },
  { title: 'MISSOURI', shortCode: 'MO' },
  { title: 'MONTANA', shortCode: 'MT' },
  { title: 'NEBRASKA', shortCode: 'NE' },
  { title: 'NEVADA', shortCode: 'NV' },
  { title: 'NEW HAMPSHIRE', shortCode: 'NH' },
  { title: 'NEW JERSEY', shortCode: 'NJ' },
  { title: 'NEW MEXICO', shortCode: 'NM' },
  { title: 'NEW YORK', shortCode: 'NY' },
  { title: 'NORTH CAROLINA', shortCode: 'NC' },
  { title: 'NORTH DAKOTA', shortCode: 'ND' },
  { title: 'OHIO', shortCode: 'OH' },
  { title: 'OKLAHOMA', shortCode: 'OK' },
  { title: 'OREGON', shortCode: 'OR' },
  { title: 'PENNSYLVANIA', shortCode: 'PA' },
  { title: 'RHODE ISLAND', shortCode: 'RI' },
  { title: 'SOUTH CAROLINA', shortCode: 'SC' },
  { title: 'SOUTH DAKOTA', shortCode: 'SD' },
  { title: 'TENNESSEE', shortCode: 'TN' },
  { title: 'TEXAS', shortCode: 'TX' },
  { title: 'UTAH', shortCode: 'UT' },
  { title: 'VERMONT', shortCode: 'VT' },
  { title: 'VIRGINIA', shortCode: 'VA' },
  { title: 'WASHINGTON', shortCode: 'WA' },
  { title: 'WEST VIRGINIA', shortCode: 'WV' },
  { title: 'WISCONSIN', shortCode: 'WI' },
  { title: 'WYOMING', shortCode: 'WY' },
  { title: 'DISTRICT OF COLUMBIA', shortCode: 'DC' },
  { title: 'MARSHALL ISLANDS', shortCode: 'MH' },
];
