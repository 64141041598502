import { Button, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { handleFileUpload } from '../../utils/Utils';

interface FileUploadProps {
  name: string;
  onChange: Function;
  ariaLabelledby: string;
  label: string;
}
const btnStyle = {
  color: 'white',
  backgroundColor: '#4a6a89',
  borderRadius: '5px',
  padding: '8px 10px',
  fontSize: '14px',
  background: '#4a6a89',
  boxShadow: 'none',
  textTransform: 'capitalize',
  '&:hover': {
    background: '#4a6a89',
    boxShadow: 'none',
  },
  display: 'inherit',
  minWidth: { xs: '100%', sm: '64px' },
  '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
    minWidth: { sm: '100%' },
  },
  justifyContent: 'center',
};

export const FileUpload: FunctionComponent<FileUploadProps> = ({
  name,
  onChange,
  ariaLabelledby,
  label,
}) => {
  const { t } = useTranslation();
  const details = navigator.userAgent;
  const regExp = /android|iphone/i;
  const isMobileDevice = regExp.test(details);
  return (
    <Button
      sx={{
        width: { xs: '100%', sm: 'initial' },
        p: { sm: '6px 5px' },
        justifyContent: {
          sm: 'flex-start',
        },
        '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
          minWidth: { sm: isMobileDevice ? '100%' : 'initial' },
        },
      }}
    >
      <InputLabel htmlFor={name} id={ariaLabelledby} sx={btnStyle}>
        <FileUploadOutlinedIcon
          sx={{
            fontSize: '20px',
            marginRight: '8px',
            display: { xs: 'none', sm: 'flex' },
            '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
              display: isMobileDevice ? 'none' : 'block',
            },
          }}
        />
        <CameraAltIcon
          sx={{
            fontSize: '20px',
            marginRight: '8px',
            display: { xs: 'flex', sm: 'none' },
            '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
              display: isMobileDevice ? 'block' : 'none',
            },
          }}
        />
        {label}
      </InputLabel>
      <input
        id={name}
        name={name}
        type='file'
        aria-labelledby={ariaLabelledby}
        multiple
        accept='image/*'
        hidden
        onChange={(e) => {
          const clearInput = () => {
            e.target.value = '';
          };
          handleFileUpload(e, onChange, clearInput, t);
        }}
      />
    </Button>
  );
};
