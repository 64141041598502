import { ChangeEvent } from 'react';
import { getI18n } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  defaultI18nLanguage,
  defaultLanguage,
  I18nLanguage,
  SupportedLanguage,
} from './Constants';

export const handleFileUpload = (
  e: ChangeEvent<HTMLInputElement>,
  onChange: any,
  cb: () => void,
  t: Function
) => {
  const selectableMaxFileSize = 1024 * 1024 * 15; // 15 Megabyte
  let initialFileSize: number = 0;

  const notify = () => {
    toast.error(t('NewLC-CreateOrder-FileSize'), { position: 'top-center' });
  };
  let isValidFile;
  if (e.target.files) {
    for (let i = 0; i < e.target.files.length; i += 1) {
      initialFileSize = e.target.files[i].size;

      if (initialFileSize > selectableMaxFileSize) {
        isValidFile = true;
      }
    }
    if (isValidFile) {
      notify();
    }
    onChange(e, cb);
  }
};

const i18nLanguageCodeMap: Record<SupportedLanguage, I18nLanguage> = {
  English: 'en-US',
  Espanol: 'es-ES',
};

export const languageNameToI18nCode = (supportedLanguage: SupportedLanguage) =>
  i18nLanguageCodeMap[supportedLanguage] || defaultI18nLanguage;

export const changeI18nLanguage = (language: SupportedLanguage) => {
  const selectedI18nLanguage = languageNameToI18nCode(language);
  const i18n = getI18n();

  if (i18n.language !== selectedI18nLanguage) {
    i18n.changeLanguage(selectedI18nLanguage);
  }
};

export const i18nLanguageCodeToName = (i18nLanguage: I18nLanguage) => {
  const foundLanguage = Object.keys(i18nLanguageCodeMap).find((key) =>
    i18nLanguageCodeMap[key as SupportedLanguage] === i18nLanguage ? key : false
  );

  return (foundLanguage as SupportedLanguage) || defaultLanguage;
};
