import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input, { type Country } from 'react-phone-number-input/input';
import { useLocation, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import image from '../../../assets/images/baby-mom.jpg';
import logo from '../../../assets/images/logo-bpd.png';
import { I18nLanguage } from '../../../common/utils/Constants';
import { badge, theme } from '../../../common/utils/Theme';
import { i18nLanguageCodeToName } from '../../../common/utils/Utils';
import { SendVerificationCodeRequest } from '../../../models/SendVerificationCodeRequest';
import { sendVerificationCode } from '../../../services/VerificationService';

const numberStepSx = {
  boxInputMain: {
    p: 0,
    width: '-webkit-fill-available',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'unset',
    display: { xs: 'none', sm: 'block' },
  },
  logoIcon: {
    color: '#e6863e',
    width: '50px',
    height: '50px',
  },
  welcomeText: {
    mt: 2,
    mb: 2,
    color: '#4a6b89',
  },
  textNumber: {
    fontWeight: '600',
    marginBottom: '5px',
    color: '#00182d',
    fontSize: '20px',
  },
  textCode: {
    fontSize: '14px',
    color: '#4a6b89',
    pt: 0.8,
    pb: 4,
  },
  countryCode: {
    color: '#000',
    fontSize: '13px',
    backgroundColor: 'rgb(245, 246, 250)',
    fontWeight: '400',
    height: '40px',
    borderColor: '#899dad',
    borderRadius: '5px',
  },
  countryInput: {
    color: 'rgb(137, 157, 173)',
    fontSize: '13px',
    backgroundColor: 'rgb(245, 246, 250)',
    fontWeight: '400',
    height: '40px',
    width: '225px',
    borderRadius: '5px',
    padding: '10px',
  },
  verificationButton: {
    backgroundColor: '#e6863e',
    margin: '50px auto 30px',
    display: 'block',
    boxShadow: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    textTransform: 'capitalize',
    padding: '8px 25px',
    fontWeight: '600',
    mt: 6,
    mb: 3,
    '&:hover': {
      background: ' #e6863e',
      boxShadow: 'none',
    },
  },
};

export const PhoneNumber: FunctionComponent<any> = () => {
  const { t, i18n } = useTranslation();
  const { state }: any = useLocation();
  const [country, setCountry] = useState<Country | undefined>('US');
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [numberInputError, setNumberInputError] = useState<string>('');
  const navigate = useNavigate();
  const handleGetVerificationCode = async () => {
    const phoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[0-9]*\d{10}$/g;
    const isValidNumber = mobileNumber.match(phoneNumberRegex);
    if (!mobileNumber || !isValidNumber) {
      setNumberInputError(t('OutPatient-Begin-PhoneNumberError'));
      return;
    }
    setNumberInputError('');
    try {
      const language = i18nLanguageCodeToName(i18n.language as I18nLanguage);
      const request = new SendVerificationCodeRequest(mobileNumber, language);
      await sendVerificationCode(request);
      navigate('/orders/otp', {
        state: { phoneNumber: mobileNumber },
        replace: true,
      });
      secureLocalStorage.setItem('phoneNumber', mobileNumber);
    } catch (error) {
      // TODO handle error
    }
  };
  const handleKeyPress = (e: React.KeyboardEvent<typeof FormControl>) => {
    if (e.key === 'Enter') {
      handleGetVerificationCode();
    }
  };
  const handleBack = () => {
    navigate(`/orders/language`);
  };
  useEffect(() => {
    if (!secureLocalStorage.getItem('language')) {
      navigate('/orders/language');
    }
  }, []);
  useEffect(() => {
    if (state?.phoneNumber) {
      setMobileNumber(state?.phoneNumber);
    }
  }, []);
  const details = navigator.userAgent;
  const regExp = /android|iphone/i;
  const isMobileDevice = regExp.test(details);
  return (
    <Box
      sx={
        process.env.REACT_APP_CUR_ENV === 'DEV' ||
        process.env.REACT_APP_CUR_ENV === 'UAT'
          ? badge
          : null
      }
    >
      <div className='badge' style={{ height: '100vh', background: 'white' }}>
        <Grid sx={{ height: '100%' }}>
          <Grid item container sx={{ height: '100%' }}>
            <Grid
              item
              xs={6}
              sx={{
                width: '-webkit-fill-available',
                backgroundImage: `url(${image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'top',
                display: { xs: 'none', sm: 'block' },
                '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
                  display: isMobileDevice ? 'none' : 'block',
                },
              }}
            />
            <Grid
              item
              xs={4}
              sm={6}
              md={6}
              sx={{
                background: 'white',
                '@media (max-width: 850px) and (min-aspect-ratio: 13/9)': {
                  flexBasis: isMobileDevice ? '100%' : '50%',
                  minWidth: isMobileDevice ? '100%' : '50%',
                },
                [theme.breakpoints.between('xs', 'sm')]: {
                  width: '100%',
                  margin: 'auto',
                  maxWidth: '100%',
                },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'block',
                  m: 'auto',
                  textAlign: 'center',
                  overflow: 'hidden',
                }}
              >
                <Grid item xs={6} sx={numberStepSx.boxInputMain} />
                <Grid sx={{ mt: 6 }}>
                  <Grid
                    component='img'
                    src={logo}
                    sx={{ width: '100%', maxWidth: '400px' }}
                  />
                  <Typography variant='h5' sx={numberStepSx.welcomeText}>
                    {t('OutPatient-Landing-Title')} to Breast Pump Depot
                  </Typography>
                </Grid>
              </Box>
              <Grid
                item
                container
                alignItems='center'
                direction='column'
                sx={{
                  pt: 4,
                  mt: 3,
                }}
              >
                <Grid item>
                  <Typography
                    component='h1'
                    variant='h5'
                    sx={numberStepSx.textNumber}
                  >
                    {t('NewOP-EnterMobileNumber-EnterMobileNumber')}
                  </Typography>
                </Grid>
                <Paper elevation={0}>
                  <Typography variant='h6' sx={numberStepSx.textCode}>
                    {t('NewOP-EnterMobileNumber-SendCode')}
                  </Typography>
                </Paper>
                <Grid
                  item
                  sx={{
                    maxWidth: '100% !important',
                    [theme.breakpoints.between('xs', 'sm')]: {
                      width: '100%',
                    },
                    '.input-error': {
                      borderColor: '#d32f2f!important',
                    },
                  }}
                >
                  <Box
                    component='div'
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <FormControl sx={{ mr: 1, minWidth: 70, border: 0 }}>
                      <Select
                        disabled
                        value={country}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={numberStepSx.countryCode}
                      >
                        <MenuItem key={country} value={country}>
                          +1
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Grid container sx={{ flexDirection: 'column' }}>
                      <Input
                        autoFocus
                        placeholder={t('Facilities-Details-PhoneNumberPrompt')}
                        country={country}
                        value={mobileNumber}
                        maxLength={14}
                        onChange={(e: string) => setMobileNumber(e)}
                        style={numberStepSx.countryInput}
                        className={numberInputError ? 'input-error' : null}
                        onKeyPress={handleKeyPress}
                      />
                      {numberInputError && (
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {numberInputError}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Box>
                  <Button
                    type='submit'
                    variant='contained'
                    sx={numberStepSx.verificationButton}
                    onClick={handleGetVerificationCode}
                  >
                    {t('NewOP-EnterMobileNumber-GetVerificationCode')}
                  </Button>
                  <Button
                    onClick={handleBack}
                    type='submit'
                    variant='contained'
                    sx={{
                      backgroundColor: '#f5f6fa',
                      margin: '50px auto 30px',
                      display: 'flex',
                      boxShadow: 'none',
                      borderRadius: '50%',
                      fontSize: '24px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '50px',
                      minWidth: '50px',
                      color: '#000',
                      padding: '0',
                      '&:hover': {
                        background: '#eef2f5',
                      },
                    }}
                  >
                    <ChevronLeftIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
