import Axios from 'axios';
import { SendVerificationCodeRequest } from '../models/SendVerificationCodeRequest';
import { ValidateVerificationCodeRequest } from '../models/ValidateVerificationCodeRequest';
import { apiUrl, apiVersion } from '../utils/Constants';

export const sendVerificationCodeEndpoint = `${apiUrl}/api/v${apiVersion}/outpatient/users/sendCode`;
export const validateVerificationCodeEndpoint = `${apiUrl}/api/v${apiVersion}/outpatient/users/validateCode`;

export const sendVerificationCode = async (
  request: SendVerificationCodeRequest
) => {
  const { data } = await Axios.post(sendVerificationCodeEndpoint, request);

  return data;
};

export const validateVerificationCode = async (
  request: ValidateVerificationCodeRequest
) => {
  const { data } = await Axios.post(validateVerificationCodeEndpoint, request);

  return data;
};
