import { SupportedLanguage } from '../common/utils/Constants';

export class SendVerificationCodeRequest {
  phoneNumber: string;
  language: SupportedLanguage;

  constructor(phoneNumber: string, language: SupportedLanguage) {
    this.phoneNumber = phoneNumber;
    this.language = language;
  }
}
